import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col, Modal, Form, Input, Select, Button, message, Switch, Divider, Tabs, Radio, ColorPicker, InputNumber, Space } from 'antd';
import { Link } from 'gatsby';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import axios from 'axios';
import SEO from '../../blocks/layout/seo';
import AppContainer from '../../blocks/layout';
import { CopyOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const FormEditor = ({ id, location }) => {
    const state = useContext(GlobalStateContext);
    const { user, selectedProjectId } = state;
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentField, setCurrentField] = useState(null);
    const [formInstance] = Form.useForm();
    const [codePreview, setCodePreview] = useState('');

    // Field types
    const fieldTypes = [
        { value: 'text', label: 'Text Input' },
        { value: 'email', label: 'Email' },
        { value: 'password', label: 'Password' },
        { value: 'number', label: 'Number' },
        { value: 'tel', label: 'Telephone' },
        { value: 'url', label: 'URL' },
        { value: 'date', label: 'Date' },
        { value: 'time', label: 'Time' },
        { value: 'datetime-local', label: 'Date and Time' },
        { value: 'month', label: 'Month' },
        { value: 'week', label: 'Week' },
        { value: 'color', label: 'Color Picker' },
        { value: 'range', label: 'Range Slider' },
        { value: 'file', label: 'File Upload' },
        { value: 'hidden', label: 'Hidden Input' },
        { value: 'textarea', label: 'Text Area' },
        { value: 'select', label: 'Dropdown' },
        { value: 'checkbox', label: 'Checkbox' },
        { value: 'radio', label: 'Radio Buttons' },
        { value: 'multiselect', label: 'Multi-Select Dropdown' },
        { value: 'checkboxgroup', label: 'Checkbox Group' },
        { value: 'radiogroup', label: 'Radio Group' },
    ];

    // Fetch form details
    const fetchFormDetails = async () => {
        if (!user || !user.cloud_name || !id) return;

        setLoading(true);
        try {
            // Fetch form details from the NoCode Forms API
            // Note: This is a placeholder endpoint as the actual endpoint for fetching form details is not provided
            const response = await axios.get(
                `${process.env.GATSBY_BASE_URL}/form-definitions/${id}`,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );

            if (response.data) {
                setForm(response.data);
                if (response.data.form.fields) {
                    setFormFields(response.data.form.fields);
                    generateCodePreview(response.data.form.fields);
                } else {
                    setFormFields([]);
                    generateCodePreview([]);
                }
            }
        } catch (error) {
            console.error("Error fetching form details:", error);
            message.error("Failed to fetch form details");
        } finally {
            setLoading(false);
        }
    };

    // Handle field click to open the edit modal
    const handleFieldClick = (field) => {
        setCurrentField(field);
        formInstance.setFieldsValue({
            field: field.field,
            label: field.label || field.field,
            type: field.type || 'text',
            required: field.required || false,
            placeholder: field.placeholder || '',
            columnWidth: field.columnWidth || '100%',
            options: field.options ? field.options.join('\n') : '',
            multiple: field.multiple || false,
            borderStyle: field.style?.borderStyle || 'solid',
            borderWidth: field.style?.borderWidth || 1,
            borderColor: field.style?.borderColor || '#d9d9d9',
            textColor: field.style?.textColor || '#000000',
            backgroundColor: field.style?.backgroundColor || '#ffffff',
        });
        setIsModalVisible(true);
    };

    // Handle modal form submission
    const handleModalOk = () => {
        formInstance.validateFields().then(values => {
            // Process options for select, checkbox group, and radio group
            let processedValues = { ...values };
            if (['select', 'multiselect', 'checkboxgroup', 'radiogroup'].includes(values.type)) {
                processedValues.options = values.options.split('\n').filter(option => option.trim() !== '');
            }

            // Add style object
            processedValues.style = {
                borderStyle: values.borderStyle,
                borderWidth: values.borderWidth,
                borderColor: values.borderColor,
                textColor: values.textColor,
                backgroundColor: values.backgroundColor,
            };

            // Remove individual style properties
            delete processedValues.borderStyle;
            delete processedValues.borderWidth;
            delete processedValues.borderColor;
            delete processedValues.textColor;
            delete processedValues.backgroundColor;

            // Update the field in the formFields array
            const updatedFields = formFields.map(field => {
                if (field.field === currentField.field) {
                    return { ...field, ...processedValues };
                }
                return field;
            });

            setFormFields(updatedFields);
            setIsModalVisible(false);

            // Generate code preview
            generateCodePreview(updatedFields);

            // Save the updated fields to the API
            saveFormFields(updatedFields);
        });
    };

    // Generate HTML/JS code preview
    const generateCodePreview = (fields) => {
        if (!fields || fields.length === 0) {
            setCodePreview('// No fields to display');
            return;
        }

        let formHtml = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Form</title>
    <style>
        .form-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            font-family: Arial, sans-serif;
        }
        .form-row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
        }
        .form-field {
            margin-bottom: 15px;
            box-sizing: border-box;
        }
        .form-field label {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
        }
        .form-field input, .form-field select, .form-field textarea {
            width: 100%;
            padding: 8px;
            box-sizing: border-box;
        }
        .required {
            color: red;
            margin-left: 3px;
        }
        .submit-button {
            background-color: #4CAF50;
            color: white;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
        .submit-button:hover {
            background-color: #45a049;
        }
    </style>
</head>
<body>
    <div class="form-container">
        <form id="myForm">
`;

        // Group fields by row based on column width
        let currentRow = [];
        let currentRowWidth = 0;
        let rows = [];

        fields.forEach(field => {
            const width = field.columnWidth === '50%' ? 0.5 : 1;

            if (currentRowWidth + width > 1) {
                rows.push([...currentRow]);
                currentRow = [field];
                currentRowWidth = width;
            } else {
                currentRow.push(field);
                currentRowWidth += width;
            }
        });

        if (currentRow.length > 0) {
            rows.push(currentRow);
        }

        // Generate HTML for each row
        rows.forEach(row => {
            formHtml += `            <div class="form-row">\n`;

            row.forEach(field => {
                const width = field.columnWidth || '100%';
                const required = field.required ? '<span class="required">*</span>' : '';
                const fieldStyle = field.style ?
                    `style="border: ${field.style.borderWidth}px ${field.style.borderStyle} ${field.style.borderColor}; color: ${field.style.textColor}; background-color: ${field.style.backgroundColor};"` : '';

                formHtml += `                <div class="form-field" style="width: ${width}; padding-right: 10px;">\n`;
                formHtml += `                    <label for="${field.field}">${field.label || field.field}${required}</label>\n`;

                switch (field.type) {
                    case 'textarea':
                        formHtml += `                    <textarea id="${field.field}" name="${field.field}" placeholder="${field.placeholder || ''}" ${field.required ? 'required' : ''} ${fieldStyle}></textarea>\n`;
                        break;
                    case 'select':
                    case 'multiselect':
                        formHtml += `                    <select id="${field.field}" name="${field.field}" ${field.multiple ? 'multiple' : ''} ${field.required ? 'required' : ''} ${fieldStyle}>\n`;
                        if (field.options && field.options.length > 0) {
                            field.options.forEach(option => {
                                formHtml += `                        <option value="${option}">${option}</option>\n`;
                            });
                        }
                        formHtml += `                    </select>\n`;
                        break;
                    case 'checkbox':
                        formHtml += `                    <input type="checkbox" id="${field.field}" name="${field.field}" ${field.required ? 'required' : ''} ${fieldStyle}>\n`;
                        break;
                    case 'checkboxgroup':
                        if (field.options && field.options.length > 0) {
                            field.options.forEach((option, index) => {
                                formHtml += `                    <div>\n`;
                                formHtml += `                        <input type="checkbox" id="${field.field}_${index}" name="${field.field}" value="${option}" ${fieldStyle}>\n`;
                                formHtml += `                        <label for="${field.field}_${index}">${option}</label>\n`;
                                formHtml += `                    </div>\n`;
                            });
                        }
                        break;
                    case 'radiogroup':
                        if (field.options && field.options.length > 0) {
                            field.options.forEach((option, index) => {
                                formHtml += `                    <div>\n`;
                                formHtml += `                        <input type="radio" id="${field.field}_${index}" name="${field.field}" value="${option}" ${fieldStyle} ${field.required ? 'required' : ''}>\n`;
                                formHtml += `                        <label for="${field.field}_${index}">${option}</label>\n`;
                                formHtml += `                    </div>\n`;
                            });
                        }
                        break;
                    default:
                        formHtml += `                    <input type="${field.type}" id="${field.field}" name="${field.field}" placeholder="${field.placeholder || ''}" ${field.required ? 'required' : ''} ${fieldStyle}>\n`;
                }

                formHtml += `                </div>\n`;
            });

            formHtml += `            </div>\n`;
        });

        formHtml += `
            <div class="form-row">
                <div class="form-field" style="width: 100%;">
                    <button type="submit" class="submit-button">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <script>
        document.getElementById('myForm').addEventListener('submit', function(event) {
            event.preventDefault();
            
            const formData = new FormData(this);
            const formFields = [];
            
            for (const [field, value] of formData.entries()) {
                formFields.push({
                    field: field,
                    value: value
                });
            }
            
            // Replace with your NoCodeAPI endpoint
            fetch('https://v1.nocodeapi.com/your-username/nForms/your-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fields: formFields })
            })
            .then(response => response.json())
            .then(data => {
                alert('Form submitted successfully!');
                document.getElementById('myForm').reset();
            })
            .catch(error => {
                console.error('Error:', error);
                alert('There was an error submitting the form.');
            });
        });
    </script>
</body>
</html>
`;

        setCodePreview(formHtml);
    };

    // Copy code to clipboard
    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(codePreview).then(() => {
            message.success('Code copied to clipboard');
        }).catch(err => {
            message.error('Failed to copy code');
            console.error('Could not copy text: ', err);
        });
    };

    // Save form fields to the API
    const saveFormFields = async (fields) => {
        if (!user || !user.cloud_name || !id) return;

        setLoading(true);
        try {
            // Update form fields in the NoCode Forms API
            // Note: This is a placeholder endpoint as the actual endpoint for updating form fields is not provided
            await axios.put(
                `${process.env.GATSBY_BASE_URL}/form-definitions/${id}`,
                {
                    fields: fields
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );

            message.success("Form fields updated successfully");
        } catch (error) {
            console.error("Error saving form fields:", error);
            message.error("Failed to save form fields");
        } finally {
            setLoading(false);
        }
    };

    // Handle modal cancel
    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    // Fetch form details when the component mounts
    useEffect(() => {
        if (user && user.cloud_name && id) {
            fetchFormDetails();
        }
    }, [user, selectedProjectId, id]);

    // Render field options based on type
    const renderFieldOptions = () => {
        const fieldType = formInstance.getFieldValue('type');

        if (['select', 'multiselect', 'checkboxgroup', 'radiogroup'].includes(fieldType)) {
            return (
                <>
                    <Form.Item
                        name="options"
                        label="Options (one per line)"
                        rules={[{ required: true, message: 'Please enter at least one option' }]}
                    >
                        <TextArea rows={4} placeholder="Option 1&#10;Option 2&#10;Option 3" />
                    </Form.Item>

                    {fieldType === 'select' || fieldType === 'multiselect' ? (
                        <Form.Item
                            name="multiple"
                            label="Allow Multiple Selection"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    ) : null}
                </>
            );
        }

        return null;
    };

    return (
        <>
            <SEO title={`Edit Form - ${form?.name || 'Loading...'} — NoCodeAPI`} />
            <AppContainer activeNav="form-builder" location={location}>
                <div className="page-content-header">
                    <h2>Edit Form: {form?.name || 'Loading...'}</h2>
                    <p>Customize your form fields</p>
                </div>

                <div className="project-edit">
                    <div style={{ marginBottom: 20 }}>
                        <Link to="/form-builder">
                            <Button>Back to Forms List</Button>
                        </Link>
                    </div>

                    <Row gutter={24}>
                        {/* Left Column - Form Fields */}
                        <Col span={12}>
                            <h3>Form Fields</h3>
                            <p>Click on a field to edit its properties</p>

                            <Row gutter={[16, 16]}>
                                {formFields.map(field => (
                                    <Col key={field.field} xs={24} sm={field.columnWidth === '50%' ? 12 : 24}>
                                        <Card
                                            title={
                                                <span>
                                                    {field.label || field.field}
                                                    {field.required && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
                                                </span>
                                            }
                                            hoverable
                                            onClick={() => handleFieldClick(field)}
                                            style={{
                                                borderColor: field.style?.borderColor || '#d9d9d9',
                                                borderWidth: field.style?.borderWidth || 1,
                                                borderStyle: field.style?.borderStyle || 'solid'
                                            }}
                                        >
                                            <p><strong>Type:</strong> {field.type || 'text'}</p>
                                            <p><strong>Width:</strong> {field.columnWidth || '100%'}</p>
                                            {field.placeholder && (
                                                <p><strong>Placeholder:</strong> {field.placeholder}</p>
                                            )}
                                            {field.options && (
                                                <p><strong>Options:</strong> {field.options.join(', ')}</p>
                                            )}
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>

                        {/* Right Column - Code Preview */}
                        <Col span={12}>
                            <h3>Code Preview</h3>
                            <p>Copy this code to include the form on your website</p>

                            <div style={{ position: 'relative' }}>
                                <Button
                                    type="primary"
                                    icon={<CopyOutlined />}
                                    onClick={copyCodeToClipboard}
                                    style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
                                >
                                    Copy Code
                                </Button>
                                <div style={{
                                    backgroundColor: '#f5f5f5',
                                    padding: '15px',
                                    borderRadius: '4px',
                                    maxHeight: '600px',
                                    overflowY: 'auto',
                                    fontFamily: 'monospace',
                                    whiteSpace: 'pre-wrap',
                                    fontSize: '12px'
                                }}>
                                    {codePreview}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        title="Edit Field"
                        visible={isModalVisible}
                        onOk={handleModalOk}
                        onCancel={handleModalCancel}
                        confirmLoading={loading}
                        width={800}
                    >
                        <Form
                            form={formInstance}
                            layout="vertical"
                        >
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Basic Settings" key="1">
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="field"
                                                label="Field Name"
                                                rules={[{ required: true, message: 'Please enter the field name' }]}
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="type"
                                                label="Field Type"
                                                rules={[{ required: true, message: 'Please select the field type' }]}
                                            >
                                                <Select>
                                                    {fieldTypes.map(type => (
                                                        <Option key={type.value} value={type.value}>{type.label}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="label"
                                                label="Label"
                                                rules={[{ required: true, message: 'Please enter the field label' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="placeholder"
                                                label="Placeholder"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="required"
                                                label="Required"
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="columnWidth"
                                                label="Column Width"
                                            >
                                                <Radio.Group>
                                                    <Radio.Button value="50%">50%</Radio.Button>
                                                    <Radio.Button value="100%">100%</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {renderFieldOptions()}
                                </TabPane>

                                <TabPane tab="Styling" key="2">
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="borderStyle"
                                                label="Border Style"
                                            >
                                                <Select>
                                                    <Option value="none">None</Option>
                                                    <Option value="solid">Solid</Option>
                                                    <Option value="dashed">Dashed</Option>
                                                    <Option value="dotted">Dotted</Option>
                                                    <Option value="double">Double</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="borderWidth"
                                                label="Border Width (px)"
                                            >
                                                <InputNumber min={0} max={10} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="borderColor"
                                                label="Border Color"
                                            >
                                                <Input type="color" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="textColor"
                                                label="Text Color"
                                            >
                                                <Input type="color" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="backgroundColor"
                                                label="Background Color"
                                            >
                                                <Input type="color" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Form>
                    </Modal>
                </div>
            </AppContainer>
        </>
    );
};

export default FormEditor;